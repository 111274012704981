import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n'; 

function setMetaTags(lang) {
  const descriptions = {
    en: "Embark on an interactive city game with your colleagues! Collect points, exchange them for rewards and compete in rankings! Go sightseeing and city exploring as a team, or go individual. Let the treasure hunt begin!",
    pl: "Gra miejska czeka na twoją firmę! Zbuduj swoją drużynę lub eksploruj solo. Pracuj nad komunikacją swojego zespołu, zdobywaj punkty i wymieniaj je na nagrody! Odkrywaj z nami nowe miejsca! Nasza gra terenowa czeka na Ciebie!",
    fr: "Embarquez dans un jeu de ville interactif avec vos collègues ! Collectez des points, échangez-les contre des récompenses et rivalisez dans les classements ! Visitez et explorez la ville en équipe, ou individuellement. Que la chasse au trésor commence !"
  };

  const keywords = {
    en: "city game, city exploring, treasure hunt, sightseeing, company team building event, interactive team game, city exploration game, corporate city game Krakow, corporate city game Warsaw",
    pl: "gra miejska Krakow, gra terenowa Kraków, gra integracyjna dla firm Kraków, wycieczka integracyjna, gra integracyjna w plenerze Kraków, gra terenowa, zwiedzanie Kraków, zwiedzanie Gdańsk, zwiedzanie Warszawa, zwiedzanie Wrocław",
    fr: "jeu de ville, exploration urbaine, chasse au trésor, visites touristiques, événement de renforcement d'équipe, jeu d'équipe interactif, jeu d'exploration urbaine, jeu d'entreprise à Cracovie, jeu d'entreprise à Varsovie"
  };

  const ogTitles = {
    en: "City games and city exploring | Corporate team building, sightseeing and urban games",
    pl: "Gry terenowe dla firm | Zwiedzaj miasta i zdobywaj punkty",
    fr: "Jeux de ville et exploration urbaine | Renforcement d'équipe, visites et jeux urbains"
  };

  const ogDescriptions = {
    en: "Engage in exciting city games, treasure hunts, and sightseeing tours. Perfect for companies looking to build teamwork and for individuals seeking adventure. Collect points, compete in rankings, and explore the city like never before.",
    pl: "Weź udział w ekscytujących grach miejskich, polowaniach na skarby i wycieczkach. Idealne dla firm szukających sposobu na budowanie zespołu i dla osób poszukujących przygody. Zbieraj punkty, rywalizuj w rankingach i odkrywaj miasto jak nigdy dotąd.",
    fr: "Participez à des jeux de ville passionnants, des chasses au trésor et des visites touristiques. Parfait pour les entreprises souhaitant renforcer l'esprit d'équipe et pour les personnes en quête d'aventure. Collectez des points, rivalisez dans les classements et explorez la ville comme jamais auparavant."
  };

  document.querySelector('meta[name="description"]').setAttribute("content", descriptions[lang]);
  document.querySelector('meta[name="keywords"]').setAttribute("content", keywords[lang]);
  document.querySelector('meta[property="og:title"]').setAttribute("content", ogTitles[lang]);
  document.querySelector('meta[property="og:description"]').setAttribute("content", ogDescriptions[lang]);
}

// Listen for language change events or set initially based on detected language
i18n.on('languageChanged', (lang) => {
  setMetaTags(lang);
});

// Set initial meta tags based on the current language
setMetaTags(i18n.language);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
