import {useTranslation} from "react-i18next";
import './how-it-works.css';

export const HowItWorks = ({header, steps}) => {
    const {i18n, t} = useTranslation();

    return (
        <section className="how-it-works">
            <h2>{t(header)}</h2>
            <div className="steps">
                {steps.map((step) => (
                    <div key={step.id}>
                        <img src={step.imgSrc} alt={`Step ${step.id}`}/>
                        <span>{step.id ? `${step.id}. ${t(step.headingKey)}` : t(step.headingKey)}</span>
                        <p dangerouslySetInnerHTML={{ __html: t(step.textKey) }}></p>
                    </div>
                ))}
            </div>

        </section>
    );
};