import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './i18n/en.json';
import translationPL from './i18n/pl.json';
import translationFR from './i18n/fr.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      order: ['navigator'],
      caches: [],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: translationEN,
      pl: translationPL,
      fr: translationFR,
    },
  });

export default i18n;