import team from '../../assets/team_allventures_2.jpeg';
import adrian from '../../assets/adrian.jpg';
import michal from '../../assets/michal.jpg';
import piotr from '../../assets/piotr.png';
import jacek from '../../assets/jacek.jpg';
import ania from '../../assets/ania.png';
import { useTranslation } from "react-i18next";

import "./about.css"


export const About = () => {
    const { i18n, t } = useTranslation();

    return (
      <div className='about'>
        <h1>{t("creators")}</h1>


        <div className='members'>
        <div className='member team'>
            <img id="team-photo" src={team}/>
          </div>
          <p style={{ width: "200px"}}>
            {t("about1")}
          </p>
          <div className='member'>
            <img id="team-photo" src={adrian}/>
          </div>
          <div className='member'>
            <img id="team-photo" src={michal}/>
          </div>
          <div className='member'>
            <img id="team-photo" src={piotr}/>
          </div>
          <div className='member'>
            <img id="team-photo" src={jacek}/>
          </div>
          <div className='member'>
            <img id="team-photo" src={ania}/>
          </div>
          <p style={{ width: "200px"}}>
          {t("about2")}
          </p>

        </div>
        
      </div> 
    );
  };