import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/index";
import './card.css';
import { useState } from 'react';


export const Card = ({image, name}) => {
  const { i18n, t } = useTranslation();

  return (
    <div className="app-card">
      <img src={image}></img>
      <p>{name}</p>
    </div>
  );
};