import {useTranslation} from "react-i18next";
import {Offert} from "../../components/offert/offert";
import loyalityProgramme from "../../assets/images/loyality-programme/loyality-programme.png";
import advert from "../../assets/images/advert/advert.png";
import {Tags} from "../../components/tags/tags";
import {HowItWorks} from "../../components/how-it-works/how-it-works";
import "./loyality-program.css"
import awards from "../../assets/images/loyality-programme/awards.png";
import services from "../../assets/images/loyality-programme/services.png";
import {Screenshots} from "../../components/screenshots/screenshots";
import visitPlaces from '../../assets/images/offert1/gra.gif';


export const LoyalityProgram = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const steps = [
        {
            "id": 1,
            "imgSrc": visitPlaces,
            "headingKey": "loyalityProgramme.howItWorks.step1.header",
            "textKey": "loyalityProgramme.howItWorks.step1.description"
        },
        {
            "id": 2,
            "imgSrc": awards,
            "headingKey": "loyalityProgramme.howItWorks.step2.header",
            "textKey": "loyalityProgramme.howItWorks.step2.description"
        },
        {
            "id": 3,
            "imgSrc": services,
            "headingKey": "loyalityProgramme.howItWorks.step3.header",
            "textKey": "loyalityProgramme.howItWorks.step3.description"
        }
    ];

    return (
        <div className="loyality-program">
            <Offert
                heading={t('loyalityProgramme.header')}
                paragraph={t('loyalityProgramme.description')}
                image={loyalityProgramme}
                buttonText={t('stepsCTA')}
                buttonCta=""
            ></Offert>

            <div className="row white-background">
                {/*<Tags tags={[*/}
                {/*    t('offert1_tag1'),*/}
                {/*    t('offert1_tag2'),*/}
                {/*    t('offert1_tag3'),*/}
                {/*    t('offert1_tag4')*/}
                {/*]}/>*/}
                <Screenshots language={currentLanguage}/>

                <HowItWorks
                    header="loyalityProgramme.howItWorks.header"
                    steps={steps}
                ></HowItWorks>

            </div>

            <Offert
                heading={t('advert.header')}
                paragraph={t('advert.description')}
                image={advert}
            ></Offert>
        </div>
    );
};