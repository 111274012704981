import {useTranslation} from "react-i18next";
import {Offert} from "../../components/offert/offert";
import trawell_report from "../../assets/images/data-trawell.png";
import {Tags} from "../../components/tags/tags";
import {HowItWorks} from "../../components/how-it-works/how-it-works";
import "./data.css"
import dataQuantity from "../../assets/images/data-quantity.gif";
import dataRoutes from "../../assets/images/data-routes.png";
import dataPanel from "../../assets/images/data-panel.png";


export const Data = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const steps = [
        {
            "id": 1,
            "imgSrc": dataQuantity,
            "headingKey": "data.howItWorks.step1.header",
            "textKey": "data.howItWorks.step1.description"
        },
        {
            "id": 2,
            "imgSrc": dataRoutes,
            "headingKey": "data.howItWorks.step2.header",
            "textKey": "data.howItWorks.step2.description"
        },
        {
            "id": 3,
            "imgSrc": dataPanel,
            "headingKey": "data.howItWorks.step3.header",
            "textKey": "data.howItWorks.step3.description"
        }
    ];

    return (
        <div className="data-component">
            <Offert
                heading={t('dataHeader')}
                paragraph={t('dataDescription')}
                image={trawell_report}
                buttonText={t('stepsCTA')}
                buttonCta=""
            ></Offert>

            <div className="row white-background">
                <Tags tags={[
                    t('data1_tag1'),
                    t('data1_tag2'),
                    t('data1_tag3'),
                    t('data1_tag4'),
                    t('data1_tag5')
                ]}/>

                <HowItWorks
                    header="data.howItWorks.header"
                    steps={steps}
                ></HowItWorks>
            </div>
        </div>
    );
};