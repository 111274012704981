import {useTranslation} from "react-i18next";
import {Offert} from "../../components/offert/offert";
import {Tags} from "../../components/tags/tags";
import {HowItWorks} from "../../components/how-it-works/how-it-works";
import "./game.css"
import createGame from "../../assets/images/offert1/panel.png";
import visitPlaces from "../../assets/images/offert1/gra.gif";
import ranking from "../../assets/images/offert1/ranking.png";
import panel from "../../assets/panel.png";
import {Screenshots} from "../../components/screenshots/screenshots";


export const Game = () => {
    const {t, i18n} = useTranslation();
    const currentLanguage = i18n.language;

    const steps = [
        {
            "id": 1,
            "imgSrc": createGame,
            "headingKey": "step1heading",
            "textKey": "step1"
        },
        {
            "id": 2,
            "imgSrc": visitPlaces,
            "headingKey": "step2heading",
            "textKey": "step2"
        },
        {
            "id": 3,
            "imgSrc": ranking,
            "headingKey": "step3heading",
            "textKey": "step3"
        }
    ];

    return (
        <div className="data-component">
            <Offert
                heading={t('steps')}
                paragraph={t('stepsDescription')}
                image={panel}
                buttonText={t('stepsCTA')}
                buttonCta=""
            ></Offert>

            <div className="row white-background">
                <Tags tags={[
                    t('offert1_tag1'),
                    t('offert1_tag2'),
                    t('offert1_tag3'),
                    t('offert1_tag4')
                ]}/>

                <HowItWorks
                    header="howItWorks"
                    steps={steps}
                ></HowItWorks>
                <h2 dangerouslySetInnerHTML={{__html: t('offertCarouselHeading')}}></h2>

                <div className="pricing">
                    <div className="price-box">
                        <div>
                            <p>
                                <b>BASIC</b><br></br>
                                {t('offert1price')}
                            </p>

                        </div>
                        <p dangerouslySetInnerHTML={{__html: t('offert1')}}/>
                    </div>

                    <div className="price-box">
                        <div className="blue-background">
                            <p>
                                <b>PRO</b><br></br>
                                {t('offert2price')}
                            </p>
                        </div>
                        <p dangerouslySetInnerHTML={{__html: t('offert2')}}/>
                    </div>

                    <div className="price-box">
                        <div>
                            <b>UNLIMITED</b><br></br>
                            {t('offert3price')}
                        </div>
                        <p>
                            <p dangerouslySetInnerHTML={{__html: t('offert3')}}/>
                        </p>
                    </div>
                </div>

                <Screenshots language={currentLanguage}/>
            </div>
        </div>
    );
};